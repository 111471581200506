import React from "react";
import "./Footer.scss";

import LocationItem from "./LocationItem";

export default function (props) {
  return (
    <footer className="footer">
      <div className="footer-cta">
        <h3>{props.global.footer_cta_title.text}</h3>
        <span
          onClick={() => {
            if (props.open) {
              props.open();
              if (window.ga) {
                window.ga(
                  "send",
                  "event",
                  "Form",
                  "Open Form",
                  "Opened Typeform"
                );
              }
            }
          }}
        >
          Click here to give us the deets.
        </span>
      </div>
      <div className="footer-inner">
        <div className="footer-top">
          <div className="logo">
            <img
              src={require("./../assets/svg/shapes1.svg")}
              alt="Colormatics Simple Logo"
            />
          </div>
          {/* <div className="social">
            <ul className="social-icons">
              <li>
                <a href={props.global.instagram.url} target="_blank">
                  <span className="icon ig"></span>
                </a>
              </li>
              <li>
                <a href={props.global.twitter.url} target="_blank">
                  <span className="icon tw"></span>
                </a>
              </li>
              <li>
                <a href={props.global.facebook.url} target="_blank">
                  <span className="icon fb"></span>
                </a>
              </li>
            </ul>
          </div> */}
        </div>

        <div className="flex-row">
          <div className="locations">
            {props.global.locations.map((location, index) => {
              return (
                <LocationItem key={`loaction-${index}`} location={location} />
              );
            })}
          </div>
        </div>
        <div className="flex-row">
          <div className="locations">
            <a className="location-item" href="/">
              Home
            </a>
            <a className="location-item" href="/services">
              Services
            </a>
            <a className="location-item" href="/case-studies">
              Case Studies
            </a>
            <a className="location-item" href="/blog">
              Blog
            </a>
            <a className="location-item" href="/industries">
              Industries
            </a>
            <a className="location-item" href="/team">
              Team
            </a>
            <a className="location-item" href="/contact">
              Contact
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
