import React, { useEffect, useState } from 'react'
// import { Link, useLocation }from 'react-router-dom'
import { Link } from 'gatsby'
import './NavigationMenu.scss'

import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'

export default function NavigationMenu({ close, nav, global, location }) {
  const [path, setPath] = useState(location.pathname)
  const [expanded, setExpanded] = useState(false)
  const [expandedIndustries, setExpandedIndustries] = useState(false)
  useEffect(() => {
    if (path !== location.pathname) {
      close()
    }
    setPath(location.pathname)
  }, [location])

  return (
    <div className="navigation-menu">
      <div className="navigation-menu__close">
        <div className="logo">
          <img
            src={require('./../assets/svg/logo-white.svg')}
            alt="Colormatics Logo"
          />
        </div>
        <span
          className="icon-close"
          onClick={() => {
            if (close) {
              close()
            }
          }}
        ></span>
      </div>
      <div className="navigation-menu__inner">
        <div className="navigation-menu__navigation">
          <ul className="navigation-menu__links">
            {nav.map((item, index) => {
              const toLink =
                item.primary.link.uid === 'homepage'
                  ? '/'
                  : `/${item.primary.link.uid}/`
              const items =
                item.items &&
                item.items[0] &&
                item.items[0].sub_nav_link.uid === null
                  ? []
                  : item.items

              return (
                <li className={`navigation-menu__link`}>
                  <Link
                    activeClassName="active"
                    className={`nav-link link-${item.primary.link.uid}`}
                    to={toLink}
                  >
                    {item.primary.label.text}
                  </Link>
                  {item.primary.label.text === 'Services' && items.length > 0 && (
                    <div
                      className={'toggle-btn'}
                      onClick={(e) => {
                        e.preventDefault()
                        setExpanded(!expanded)
                      }}
                    >
                      {expanded ? '-' : '+'}
                    </div>
                  )}
                  {item.primary.label.text === 'Services' && items.length > 0 && (
                    <SlideDown
                      as="ul"
                      className={'navigation-menu__sub-links'}
                      closed={!expanded}
                    >
                      {expanded === true &&
                        items.map((subItem, index) => {
                          return (
                            <li className="navigation-menu__sub-link">
                              <Link
                                to={`/service/${subItem.sub_nav_link.uid}/`}
                              >
                                {subItem.sub_nav_link_label.text}
                              </Link>
                            </li>
                          )
                        })}
                    </SlideDown>
                  )}
                  {item.primary.label.text === 'Industries' &&
                    items.length > 0 && (
                      <div
                        className={'toggle-btn'}
                        onClick={(e) => {
                          e.preventDefault()
                          setExpandedIndustries(!expandedIndustries)
                        }}
                      >
                        {expandedIndustries ? '-' : '+'}
                      </div>
                    )}
                  {item.primary.label.text === 'Industries' &&
                    items.length > 0 && (
                      <SlideDown
                        as="ul"
                        className={'navigation-menu__sub-links'}
                        closed={!expandedIndustries}
                      >
                        {expandedIndustries === true &&
                          items.map((subItem, index) => {
                            return (
                              <li className="navigation-menu__sub-link">
                                <Link
                                  to={`/industry/${subItem.sub_nav_link.uid}/`}
                                >
                                  {subItem.sub_nav_link_label.text}
                                </Link>
                              </li>
                            )
                          })}
                      </SlideDown>
                    )}
                </li>
              )
            })}
          </ul>
        </div>
        {/* <div className="navigation-menu__social">
          <ul className="navigation-menu__social-links">
            <li>
              <a href={global.instagram.url} target="_blank">
                <span className="icon icon--white ig"></span>
                <span className="verticle-text">
                  <span className="text">Instagram</span>
                </span>
              </a>
            </li>
            <li>
              <a href={global.twitter.url} target="_blank">
                <span className="icon icon--white tw"></span>
                <span className="verticle-text">
                  <span className="text">Twitter</span>
                </span>
              </a>
            </li>
            <li>
              <a href={global.facebook.url} target="_blank">
                <span className="icon icon--white fb"></span>
                <span className="verticle-text">
                  <span className="text">Facebook</span>
                </span>
              </a>
            </li>
          </ul>
        </div> */}
      </div>
    </div>
  )
}
